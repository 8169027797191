import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Articles from '../components/Articles'
import BlogHeader from '../components/BlogHeader'
import Container from '../components/Container'

const BlogPage = () => (
  <Layout color="indigo">
    <SEO title="Blog" />
    <BlogHeader />
    <Container>
      <Articles />
    </Container>
  </Layout>
)

export default BlogPage
