import React from 'react'
import { array, shape } from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import { Section } from './Basic'

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -100px;
`

const Post = styled.article`
  flex: 0 0 33.33333%;
  max-width: 33.33333%;

  h2 {
    font-size: 1.5em;
    font-weight: 700;
    margin: 0.5em 0;
  }

  @media (max-width: 769px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 500px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const PostInner = styled.div`
  padding: 0 10px;
`

const PostLink = styled(Link)`
  display: block;

  h2 {
    color: ${theme.colors.black};
    transition: color 0.2s;
  }

  p {
    color: ${theme.colors.material.bluegrey.primary};
  }

  &:hover {
    h2 {
      color: ${theme.colors.material.indigo.primary};
    }

    p {
      color: ${theme.colors.material.indigo['900']};
    }
  }
`

const PostImage = styled.img`
  max-width: 100%;
`

const Articles = ({
  data: {
    allMarkdownRemark: { edges: posts },
  },
}) => (
  <Section>
    <List>
      {posts &&
        posts.map(({ node: post }) => (
          <Post key={post.id}>
            <PostInner>
              <header>
                <PostLink to={post.fields.slug}>
                  {post.frontmatter.featuredImage && (
                    <PostImage
                      src={post.frontmatter.featuredImage}
                      alt={post.frontmatter.title}
                    />
                  )}
                  <h2>{post.frontmatter.title}</h2>
                  <p>{post.frontmatter.description}</p>
                </PostLink>
              </header>
            </PostInner>
          </Post>
        ))}
      {posts && posts.length === 0 && <p>Coming soon...</p>}
    </List>
  </Section>
)

Articles.propTypes = {
  data: shape({
    allMarkdownRemark: shape({
      edges: array,
    }),
  }).isRequired,
}

export default () => (
  <StaticQuery
    query={graphql`
      query ArticlesQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { eq: "blog-post" }
              published: { eq: true }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                published
                featuredImage
                description
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Articles data={data} count={count} />}
  />
)
