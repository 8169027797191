import styled from '@emotion/styled'
import theme from '../styles/theme'

const Product = styled.span`
  display: flex;
  font-size: 0.9em;
  margin-bottom: 1em;
`

const ProductDetails = styled.div`
  margin-left: 10px;
`

const Section = styled.div`
  margin-top: 2em;
`

const SubsectionIcon = styled.div`
  margin: 2em 0 1em 0;
  width: 50px;
  height: 50px;
`

const SubsectionTitle = styled.h3`
  font-weight: 700;
  margin: 1em 0;
`

const SubsectionSubtitle = styled.h4`
  color: ${theme.colors.material.bluegrey['700']};
  font-size: 1.5em;
  line-height: 1.475em;
  margin: 1em 0;

  @media (max-width: 769px) {
    margin-bottom: 2em;
  }
`

const SubsectionCTAs = styled.div`
  margin: 1em 0 2em 0;

  @media (max-width: 769px) {
    display: none;
  }
`

const SubsectionMobileCTAs = styled.div`
  display: none;
  margin: 2em 0;

  @media (max-width: 769px) {
    display: block;
  }
`

export {
  Product,
  ProductDetails,
  Section,
  SubsectionIcon,
  SubsectionTitle,
  SubsectionSubtitle,
  SubsectionCTAs,
  SubsectionMobileCTAs,
}
